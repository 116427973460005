import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import styled from "styled-components"

const Intro = styled.div`
    color: #333;
    font-size: 1.6rem;
    line-height: 1.3;
    margin-bottom: 3rem;
    @media only screen and (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 1.2;
    }
`

const Headline = styled.h1`
    font-size: 5rem;
    margin: 2rem 0rem 1rem 0rem;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      font-size: 3rem;
    }
`

const Body = styled.p`
    font-family: "Literata";
    font-size: 1.8rem;
    margin-bottom: 1rem;
    line-height: 1.8;
`

const Anchor = styled.a`
  color: #0D5C00;
  font-weight: 600;
  text-decoration: none;
`

class SuccessPage extends React.Component {
    render() {
      return (
        <Layout>
            <SEO
                title="Payment successful"
                keywords={[`success`, `confirmation`]}
            />
            <Intro>
                <Headline>Congratulations!</Headline>
                <Body>Welcome to the New Pragmatic community. We’re excited to participate in your next phase of professional growth.</Body>
                <Body>Your payment was successfully received and you’ll soon receive a welcome email outlining everything you need to know to get started with the program. Should you need anything in the meantime, don’t hesitate to email Chris Courtney at <Anchor href="mailto:chris@newpragmatic.com">chris@newpragmatic.com</Anchor>.</Body>
                <Body>While you are just getting settled in, this is a great time to schedule your first session with Chris. You can use <Anchor href="https://calendly.com/np-chris/enrolled-mentoring-session">this link to schedule your session</Anchor>.</Body>
                <Body>If you are ready to dive into the curriculum right now, check out the <Anchor href="/courses">courses page</Anchor> and selecting one of the course options, like <Anchor href="/ux">UX</Anchor>, <Anchor href="/interface">Interface</Anchor>, or <Anchor href="/careers">Careers</Anchor>.</Body>
            </Intro>
            <img width="100%" src="https://newprag.s3.amazonaws.com/success/success.gif"/>
        </Layout>
        )
    }
}

export default SuccessPage